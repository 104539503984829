.custom-scrollbars__button {
  color: #888;
}

.custom-scrollbars__button:hover{
  color: #666;
}

/* vertical */
.custom-scrollbars__scrollbar-vertical {
  display: grid;
  grid-auto-flow: row;
  grid-template: auto 1fr auto / 1fr;
  place-items: center;
}

.custom-scrollbars__track-and-thumb-vertical {
  display: block;
  height: 100%;
  position: relative;
  width: 9px;
}

.custom-scrollbars__track-vertical {
  border-radius: 6px;
  bottom: 0;
  position: absolute;
  top: 0;
  width: 9px;
}

.custom-scrollbars__thumb-vertical {
  border-radius: 6px;
  background-color: #888;
  position: absolute;
  width: 9px;
}

.custom-scrollbars__thumb-vertical:hover{
  background-color: #666;
}

/* horizontal */
.custom-scrollbars__scrollbar-horizontal {
  display: grid;
  grid-auto-flow: column;
  grid-template: auto / auto 1fr auto;
  place-items: center;
  margin-left: 8px;
}

.custom-scrollbars__track-and-thumb-horizontal {
  display: block;
  width: 100%;
  position: relative;
  height: 9px;
}

.custom-scrollbars__track-horizontal {
  border-radius: 6px;
  right: 0;
  position: absolute;
  left: 0;
  height: 9px;
}

.custom-scrollbars__thumb-horizontal {
  border-radius: 6px;
  background-color: #888;
  position: absolute;
  height: 9px;
}

.custom-scrollbars__thumb-horizontal:hover{
  background-color: #666;
}

html, body {
  overscroll-behavior: none;
}