.graph-screen {
    width: 50%;
}

@media screen and (max-width: 1400px) {
.graph-screen {
    width: 62%;
}
}


@media screen and (max-width: 1200px) {
    .graph-screen {
        width: 55%;
    }
}

@media screen and (max-width: 1080px) {
    .graph-screen {
        width: 70%;
    }
}

.custom-scrollbars__container {
    background-color: white;
    height: 100%;
    display: grid;
    grid-template: auto 16px / auto 16px;
    overflow: hidden;
    position: relative;
}

.custom-scrollbars__content{
    height: calc(100vh - 142px);
    -ms-overflow-style: none;
    overflow: auto;
    scrollbar-width: none;
}

.custom-scrollbars__content::-webkit-scrollbar {
    display: none;
}