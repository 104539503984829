.menu-option-hr-style {
    border: 0.1px solid #dcdcdc;
    margin: 0;
}

.child {
    display: none;
}

.parent:hover>.child {
    display: block;
    position: absolute;
}

.parent:hover {
    background-color: #e6f7ff;
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}